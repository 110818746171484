import { Link } from "gatsby"
import React from "react"
import { Container, Row, Col } from 'reactstrap';

class PrevSec extends React.Component {

  getShit(){
    const cards = this.props.allposts.edges.map((post, index) => {
      if(post.node.wordpress_id === this.props.post){
        if(post.previous !== null){
          return (
          <Col xs='6' className='control-col'>
            <div className='control-content'>
              <h4 className='control-text'>{this.props.lang === 'pl' ? ('POPRZEDNI POST') : ('PREV POST')}</h4>
              {this.props.lang === 'pl' ? <><Link to={'/blog/' + post.previous.slug}></Link><span className='postslug'>{post.previous.slug}</span></> : <><Link to={'/en/blog/' + post.previous.slug}></Link><span className='postslug'>{post.previous.slug}</span></>}
        </div>
        </Col>
          );
        } else {
          return (
          <Col xs='6' className='control-col'>
          </Col>
          );
        }
      }
  });
  return cards;
  }
    render() {
      return (
      <>
        {this.getShit()}
        </>
      );
    }
  }


export default PrevSec;