import { Link } from "gatsby"
import React from "react"
import { Container, Row, Col } from 'reactstrap';

class NextSec extends React.Component {
  getShit(){
    const cards = this.props.allposts.edges.map((post, index) => {
      if(post.node.wordpress_id === this.props.post){
        if(post.next !== null){
          return (
          <Col xs='6' className='control-col'>
            <div className='control-content'>
              <h4 className='control-text'>{this.props.lang === 'pl' ? ('NASTĘPNY POST') : ('NEXT POST')}</h4>
              {this.props.lang === 'pl' ?
               <><Link to={'/blog/' + post.next.slug}></Link><span className='postslug'>{post.next.slug}</span></>
               :
               <><Link to={'/en/blog/' + post.next.slug}></Link><span className='postslug'>{post.next.slug}</span></>
               }
              
        </div>
        </Col>
          );
        } else {
          return (
          <Col xs='6' className='control-col'>
          </Col>
          );
        }
      }
  });
  return cards;
  }
    render() {
      return (
      <>
        {this.getShit()}
        </>
      );
    }
  }


export default NextSec;