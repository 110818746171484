import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import NewCompCat from "../components/newcompcat";
import NewCompTag from "../components/newcomptag";

export default function Blogsidebaren() {
  return (
    <StaticQuery
      query={ graphql`
      query ajajaj {
        allWordpressPost(sort: {fields: date, order: DESC}, filter: {acf: {lang: {eq: "pl"}}}) {
          nodes {
            content
            date(formatString: "DD MMM")
            slug
            title
            featured_media {
              localFile {
                url
              }
            }
            author {
              name
            }
            categories {
              slug
              name
            }
          }
        }
        allWordpressAcfPosts(filter: {acf: {lang: {eq: "pl"}}}) {
          nodes {
            acf {
              tag
              kategorie
            }
          }
        }
     
      }
      
    `}
      render={data => (
        <>
            <div className='last-posts'>
          <div className='inner'>
            <div className='sidebar-title-cont'>
              <h4 className='sidebar-title'>OSTATNIE POSTY</h4>
            </div>
                <ul>
                  {data.allWordpressPost.nodes.map((val, index) => 
                  {if(index < 3) {
                    return (
                      <li key={index}>
                        <Link to={'/blog/' + val.slug}>
                        <div className='post-box'>
                          <div className='img-box'>
                              <img src={val.featured_media.localFile.url}></img>
                          </div>
                            <h3 className='title'>
                              {val.title}
                            </h3>
                        </div>
                        </Link>
                      </li> 
                    )
                  }}   
                  )}
                </ul> 
          </div>
        </div>
        <div className='categories'>
          <div className='inner'>
            <div className='sidebar-title-cont'>
              <h4 className='sidebar-title'>KATEGORIE</h4>
            </div>
              <ul>
                <NewCompCat lang='pl' data={data.allWordpressAcfPosts.nodes}></NewCompCat>
                {/* {data.wordpressAcfPosts.acf.kategorie.map((val, index) => 
                        <li key={index}>
                          <a href={'/blog/' + val.split(':')[1]}>
                            {val.split(':')[0]}
                          <FontAwesomeIcon className='icon-arr' icon={faChevronRight}/>
                          </a>
                        </li> 
                    )} */}
              </ul>
          </div>
        </div>
        <div className='categories'>
          <div className='inner'>
            <div className='sidebar-title-cont'>
              <h4 className='sidebar-title'>TAGI</h4>
            </div>
              <ul>
              <NewCompTag lang='pl' data={data.allWordpressAcfPosts.nodes}></NewCompTag>
              {/* {data.wordpressAcfPosts.acf.tag.map((val, index) => 
                        <li key={index}>
                          <a href={'/blog/' + val.split(':')[1]}>
                            {val.split(':')[0]}
                          <FontAwesomeIcon className='icon-arr' icon={faChevronRight}/>
                          </a>
                        </li> 
                    )} */}
              </ul>
          </div>
        </div>
      </>
      )}
    />
  )
}
