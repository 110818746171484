import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'
import Blogsidebarpl from "../components/blogsidebarpl"
import Blogsidebaren from "../components/blogsidebaren"
import Prev from "../components/prev"
import Next from "../components/next"
import ScrollAnimation from 'react-animate-on-scroll';

const BlogPostTemplate = ({ data, pageContext }) => (
  <>
  <div className="small-banner-content">
  <div title={data.wordpressPage.acf.blog_banner_img.alt_text} style={{backgroundImage: `url('` + data.wordpressPage.acf.blog_banner_img.localFile.url + `')`, backgroundPosition: `center`}} className="background-small"></div>
        <div className="text-on-banner">  
        <ScrollAnimation animateOnce='true' animateIn='fadeInUp' delay='100' duration='0.5'>
        <h1 className='smallbanner-title'>BLOG</h1>
        </ScrollAnimation>
      </div>
      <div className='right-bottom-curve-cont'>
      <ScrollAnimation animateOnce='true' animateIn='fadeInRight' delay='200' duration='0.5'>
        <div className="right-bottom-curve"></div>
      </ScrollAnimation>
      </div>
      </div>
  <Layout lang={pageContext.lang} pageInfo={{ pageName: "blogpost" }}>

<Container className='blog-post'>
  <Row>
    <Col lg='8' className='content'>
      <div className='blog-details'>
        <div className='post-details'>
          <div className='image-box'>
            <a>
            {pageContext.lang === 'pl' ? 
              <img src={data.wordpressPost.featured_media.localFile.url} alt={data.wordpressPost.featured_media.alt_text}></img>
              :
              <img src={data.wordpressPost.featured_media.localFile.url} alt={data.wordpressPost.featured_media.acf.alt_en}></img>
            }
            </a>
          </div>
          <div className='lower-box'>
            <div className='post-meta'>
              <ul className='clearfix'>
                  <li>
                    <FontAwesomeIcon className='icons' icon={faClock} />
                    {data.wordpressPost.date}
                  </li>
                  <li>
                  <FontAwesomeIcon className='icons' icon={faUserCircle} />
                    {data.wordpressPost.author.name}
                  </li>
              </ul>
            </div>
            <h4 className='post-title'>{data.wordpressPost.title}</h4>
            <div className='text' dangerouslySetInnerHTML={{ __html: data.wordpressPost.content }}></div>
          </div>
          <div className='info-row clearfix'>
          {pageContext.lang === 'pl' ? 
          <>
            <div className='tags-info'>
              <span className='maintag'>TAGI: </span>
              {data.wordpressPost.acf.tag.map((val, index) => 
                            <a href={'/blog/' + val.split(':')[1]} key={index}>
                              <span className='taglist'>{val.split(':')[0]} </span>
                            </a>
              )}
            </div>
            <div className='cat-info'>
              <span className='maintag'>KATEGORIE: </span>
                {data.wordpressPost.acf.kategorie.map((val, index) => 
                              <a href={'/blog/' + val.split(':')[1]} key={index}>
                                <span className='taglist'>{val.split(':')[0]} </span>
                              </a>
                )}
            </div>
          </>
          :
          <>
            <div className='tags-info'>
              <span className='maintag'>TAGS: </span>
              {data.wordpressPost.acf.tag.map((val, index) => 
                            <a href={'/en/blog/' + val.split(':')[1]} key={index}>
                              <span className='taglist'>{val.split(':')[0]} </span>
                            </a>
              )}
            </div>
            <div className='cat-info'>
              <span className='maintag'>CATEGORY: </span>
                {data.wordpressPost.acf.kategorie.map((val, index) => 
                              <a href={'/en/blog/' + val.split(':')[1]} key={index}>
                                <span className='taglist'>{val.split(':')[0]} </span>
                              </a>
                )}
            </div>
          </>
          }
            

          </div>
        </div>

        {data.allWordpressPost.edges.length > 1 ? 
        <div className='post-control'> 
        <Row>
          <Prev lang={pageContext.lang} allposts={data.allWordpressPost} post={data.wordpressPost.wordpress_id}></Prev>
          <Next lang={pageContext.lang} allposts={data.allWordpressPost} post={data.wordpressPost.wordpress_id}></Next>
        </Row> 
      </div>
       : 
      <div className='post-control'> 
      </div>
        } 

      </div>
    
    </Col>
    <Col lg='4' className='sidebar'>
    {pageContext.lang === 'pl' ? 
      <Blogsidebarpl/>
      :
      <Blogsidebaren/>
      }
    </Col>
  </Row>
</Container>
  </Layout>
  </>
) 
export default BlogPostTemplate
export const query = graphql`
  query($id: Int!, $lang: String!) {
    wordpressPost(wordpress_id: { eq: $id }) {
      wordpress_id
      title
      slug
      id
      excerpt
      content
      date(formatString: "DD MMM", locale: $lang)
      featured_media {
        localFile {
          url
        }
        alt_text
        acf {
          alt_en
        }
      }
      author {
        name
      }
      acf {
        kategorie
        tag
      }
    }
    wordpressPage(title: {eq: "Blog"}) {
      acf {
        blog_banner_img {
          localFile {
            url
          }
          alt_text
          acf {
            alt_en
          }
        }
      }
    }
    allWordpressPost(filter: {acf: {lang: {eq: $lang}}}) {
      edges {
        node {
          id
          wordpress_id
        }
        next {
          slug
        }
        previous {
          slug
        }
      }
    }
  }
`
