import React from "react"
import { Link } from "gatsby"
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

class SectionBanner extends React.Component {
    constructor(props) {
        super(props);
       this.state = [];
       this.final = [];
      }
    componentWillMount(){
    
        
        this.props.data.map((val, index) => {
            val.acf.kategorie.map((valu, index) => {
        this.state.push(valu)
    })
        })
        let uniqueString = [...new Set(this.state)];
        
        this.final = uniqueString;
        console.log(this.final);
    }
    render() {
      
      return (
          <>
         {this.final.map((val, index) =>
                        <li key={index}>
                          {this.props.lang === 'pl' ?
                          <Link to={'/blog/' + val.split(':')[1]}>
                          {val.split(':')[0]}
                        <FontAwesomeIcon className='icon-arr' icon={faChevronRight}/>
                        </Link>
                        :
                        <Link to={'/en/blog/' + val.split(':')[1]}>
                            {val.split(':')[0]}
                          <FontAwesomeIcon className='icon-arr' icon={faChevronRight}/>
                          </Link>
                        }
                          
                        </li> 
                    )}
        </>

      );

    }
  }


export default SectionBanner;